<template>
    <div class="disertation_box">
        <!-- 英文查重 -->
        <el-container v-bind:style="{ height: Height + 'px' }">
            <el-main v-bind:style="{ height: Height + 'px' }">
                <CHECK v-if="keys == '1'"></CHECK>
                <div v-if="keys == '2'">
                    <PROOFREAD v-if="isRouterAlive"></PROOFREAD>
                </div>
                <div v-if="keys == '4'">
                    <WRITEFUL v-if="isRouterAlive"></WRITEFUL>
                </div>
                <REPORTLIST v-if="keys == '3'"></REPORTLIST>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import CHECK from "./check_proofread/check.vue";
import PROOFREAD from "./check_proofread/proofread.vue";
import REPORTLIST from "./check_proofread/reportList.vue";
import WRITEFUL from "./check_proofread/writeful.vue";
import { writefulUser, getWriteful } from "@/Api/scholarcy_writeful01";
export default {
    inject: ["reloads"],
    provide() {
        return {
            reloading: this.reloading,
            reload: this.reload,
            // Height: 0,
        };
    },
    data() {
        return {
            Height: 0,
            keys: "1",
            activeIndex: "1",
            isRouterAlive: true,
            isRouterAlive_menu: true,
        };
    },
    mounted() {
        //动态设置内容高度 让footer始终居底   header+footer的高度是190
        // console.log(this.name)
        this.Height = document.documentElement.clientHeight - 80; //监听浏览器窗口变化
        window.onresize = () => {
            this.Height = document.documentElement.clientHeight - 80;
        };
    },
    created() {
        // this.unreads();
        if (this.$route.query.webPage) {
            this.keys = this.$route.query.webPage.toString();
            this.activeIndex = this.$route.query.webPage.toString();
        }
    },
    // 监听路由变化
    watch: {
        $route(to, from) {
            if (to.query.webPage) {
                this.keys = to.query.webPage.toString();
            }
        },
    },
    methods: {
        reloading() {
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            });
        },
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            });
        },
        handleSelect(key) {
            // console.log(key);
            if (key == 4 && !localStorage.getItem("writful_permissions")) {
                // this.keys = key;
                // this.activeIndex = key;
                // this.keys = this.$route.query.webPage;
                // this.activeIndex = this.$route.query.webPage;

                var that = this;
                setTimeout(function () {
                    // that.keys = that.$route.query.webPage;
                    // that.activeIndex = that.$route.query.webPage;
                    // console.log(that.activeIndex)
                    that.reloads();
                }, 1000);
                this.$message("暂无权限~");
            } else {
                this.keys = key;
                this.activeIndex = key;
                this.$router.push({
                    name: "Dissertation",
                    query: { webPage: key },
                });
                if (this.keys == 1) {
                    this.reloads();
                }
                if (this.keys == 2) {
                    this.reloading();
                }
                if (this.keys == 4) {
                    this.reloading();
                }
            }
            // return
            if (key == 4) {
                let scholary_code01 = {
                    org_name: localStorage.getItem("org_name"),
                    open_id: localStorage.getItem("code"),
                };
                getWriteful(scholary_code01)
                    .then((res) => {
                        // console.log("a", res);
                        if (!res.data.token.token) {
                            let scholary_code_edit01 = {
                                org_name: localStorage.getItem("org_name"),
                                open_id: localStorage.getItem("code"),
                                name: localStorage.getItem("name"),
                                org_code: localStorage.getItem("org_ode"),
                            };
                            writefulUser(scholary_code_edit01)
                                .then((res) => {
                                    // console.log(res);
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        }
                        // this.$router.push({
                        //   name: key,
                        // });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }

            // return;

        },
    },
    components: {
        CHECK,
        PROOFREAD,
        REPORTLIST,
        WRITEFUL,
    },
};
</script>

<style lang="less" scoped>
.disertation_box {
    min-width: 1360px;
    box-sizing: border-box;
    .el-aside {
        background-color: #fff;
        color: #333;
        text-align: center;
        line-height: 200px;
    }

    .el-main {
        // height: 100%;
        background-color: #e9eef3;
        color: #333;
        // text-align: center;
        // line-height: 160px;
    }

    body>.el-container {
        margin-bottom: 40px;
    }

    .el-container:nth-child(5) .el-aside,
    .el-container:nth-child(6) .el-aside {
        line-height: 260px;
    }

    .el-container:nth-child(7) .el-aside {
        line-height: 320px;
    }

    .el-menu {
        background-color: #fff;
    }
}

.is-active div {
    background-color: #B14A75;

    span {
        color: #fff;
    }

    i {
        color: #fff;
    }
}

.el-menu-item {
    padding: 5px 50px !important;
    background-color: #fff;
    margin-bottom: 5px;
}

.aside_item {
    width: 100%;
    // height: 50px;
    // line-height: 50px;
    border-radius: 5px;

    span {
        color: #b1aec8;
        font-size: 15px;
    }
}

.item {
    line-height: 30px;

    /deep/ .el-badge__content.is-fixed {
        position: absolute;
        top: 5px;
        right: 13px;
        transform: translateY(-50%) translateX(100%);
    }
}

.collating {
    display: inline-block;
    line-height: 20px;
}
</style>