<template>
  <!-- 查重、校对 -->
  <div class="check">
    <div class="check_box">
      <div class="title">
        <h2 style="text-align: center; font-size: 25px">
          外文学术不端文献检测系统
        </h2>
      </div>
      <div class="describes">
        <checkDetect
          style="width: 1200px"
          @checkup="open"
          v-if="detects"
        ></checkDetect>
      </div>

      <div class="check_again" v-if="checkAgain">
        <div class="prompt">
          <el-steps :active="3">
            <el-step title="上传文件" icon="el-icon-upload"></el-step>
            <el-step title="进行检测" icon="el-icon-data-analysis"></el-step>
            <el-step title="生成报告" icon="el-icon-tickets"></el-step>
          </el-steps>
          <div class="prompt_text">
            <span class="arrow">支持</span><i class="el-icon-arrow-right"></i
            ><span class="text"
              >适用范围：本系统<span
                style=" color: red"
                >仅支持英文文章查重</span
              >，适用于外语毕业论文、留学生作业、SCI\EI\ACS等期刊、职称论文等原创性要求高的文本。</span
            >
            <!-- font-size: 14px; -->
          </div>
          <div class="prompt_text">
            <span class="arrow">说明</span><i class="el-icon-arrow-right"></i
            ><span class="text"
              >检测要求：仅针对未发表文章，包括引用内容和参考文献。为确保报告准确性，请提交完整文章进行检测。</span
            >
          </div>
          <div class="prompt_text">
            <span class="arrow">有效期</span><i class="el-icon-arrow-right"></i
            ><span class="text"
              >报告有效期：报告在平台保留一周，期间可多次下载使用。</span
            >
          </div>
          <!-- <img style="width: 100%" src="../../../assets/imgs/上传.png" alt="" /> -->
        </div>
        <div class="upLoad">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            name="file"
            :data="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
            id="uploadForm"
          >
            <!-- <el-form-item label="文章标题" prop="title">
            <el-input v-model="ruleForm.title"></el-input>
          </el-form-item>
          <el-form-item label="文章作者" prop="author">
            <el-input v-model="ruleForm.author"></el-input>
          </el-form-item>
          <el-form-item label="检测类型" prop="task_type">
            <el-radio-group v-model="ruleForm.task_type">
              <el-radio :label="0">英文查重</el-radio>
              <el-radio :label="1">语法校对</el-radio>
            </el-radio-group>
          </el-form-item> -->
            <!-- <el-form-item label="文件上传" prop="file"> -->
            <div class="upDemo" v-if="isRouterAlive">
              <el-upload
                class="upload-demo"
                id="uploadForm"
                drag
                :action="actionUrl"
                :headers="{ token: token }"
                :before-upload="beforeUpload"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :on-change="on_change"
                :before-remove="beforeRemove"
                multiple
                :limit="1"
                :file-list="fileList"
                :on-exceed="handleExceed"
                :auto-upload="false"
              >
                <div class="upStyle">
                  <div style="margin: 0 0 50px 50px">
                    <div style="">
                      <i
                        class="el-icon-upload"
                        style="
                          font-size: 67px;
                          color: #c0c4cc;
                          margin: 40px 0 16px;
                          line-height: 50px;
                        "
                      ></i>
                      <div class="el-upload__text">
                        <em style="font-style: normal">点击上传</em>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="el-upload__tip" slot="tip">只能上传txt/docx/pdf文件</div> -->
              </el-upload>
              <div class="upload_tip">
                <img
                  class="upload_tip_img"
                  src="../../../assets/imgs/tip.307c8b1.png"
                  alt=""
                />
                <div class="upload_tip_text">
                  <p class="upload_tip_p">
                    仅支持英文查重，文档需小于10万字符，30MB；
                  </p>
                  <!-- <p class="upload_tip_p">
                    文档大小必须小于10万字符， 4 MB大小
                  </p> -->

                  <p class="upload_tip_p">文档至少包含100个字符；</p>

                  <p class="upload_tip_p">
                    支持txt、docx、pdf格式文件上传；
                    <br />
                  </p>
                  <p class="upload_tip_p">
                    上传前请去除文档内中文、图片、批注等非查重内容；
                    <br />
                  </p>
                  
                  <p class="upload_tip_p">
                    docx文件上传前需取消修订（
                    <span class="hovers" @click="link">取消方法</span> ）。
                  </p>
                  <!-- <p class="upload_tip_p">
                    上传前请清除文档内中文, 图片, 批注及其他不需要校对的内容。
                  </p> -->
                </div>
              </div>
            </div>
            <!-- </el-form-item>
          <el-form-item> -->
            <div class="btn">
              <el-button type="primary" @click="submitForm()" :loading="returns"
                >提&emsp;交</el-button
              >
            </div>
            <!-- </el-form-item> -->
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkDetect from "./Dtect/check_detect.vue";
import store from "@/store";
import { checks } from "@/Api/checkAgain";
import $ from "jquery";
import qs from "qs";
import { Message } from "element-ui";
export default {
  inject: ["reloading"],
  provide() {
    return {
      reloadProof: this.reloadProof,

      // Height: 0,
    };
  },
  data() {
    return {
      // actionUrl: "https://xcx.newacademic.net/api/extend/upload_file/",
      actionUrl: process.env.VUE_APP_API_BASE_URL + "extend/upload_file/",
      // actionUrl: store.getCookie("baseURL") + "extend/upload_file/",
      // actionUrl: "https://ql-test.newacademic.net/api/extend/upload_file/",
      token: store.getCookie("token"),
      ruleForm: {
        title: "",
        author: "",
        task_type: null,
        file: "",
      },
      rules: {
        // title: [{ required: true, message: "请输入文章标题", trigger: "blur" }],
        // author: [
        //   { required: true, message: "请输入文章作者", trigger: "blur" },
        // ],
        // task_type: [
        //   { required: true, message: "请选择检测类型", trigger: "change" },
        // ],
        file: [
          { required: true, message: "请选择文件上传", trigger: "change" },
        ],
      },
      fileList: [],
      checkAgain: false,
      detects: true,
      isRouterAlive: true,
      returns: false,
    };
  },
  created() {
    // console.log('process.env.VUE_APP_API_BASE_URL',process.env.VUE_APP_API_BASE_URL + "extend/upload_file/")
    //   return
  },
  methods: {
    // 打开查重
    open(data) {
      // console.log(data);
      if (data == "true") {
        this.checkAgain = true;
        this.detects = false;
      }
    },

    handleRemove(file, fileList) {
      // console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    // 上传前处理
    beforeUpload(file) {
      console.log(1111);
      // let fileSize = file.size;
      // const FIVE_M = 5 * 1024 * 1024;
      // //大于5M，不允许上传
      // if (fileSize > FIVE_M) {
      //   this.$message.error("最大上传5M");
      //   return false;
      // }
      //判断文件类型，必须是xlsx格式
      // let fileName = file.name;
      // let reg = /^.+(\.xlsx)$/;
      // if (!reg.test(fileName)) {
      //   this.$message.error("只能上传xlsx!");
      //   return false;
      // }
      // return true;
    },
    on_change(file, fileList) {
      // if(file.size > 31457280){
      //   this.$message.error('文件过大，请更换文件重试')
      //   return
      // }else{
        this.ruleForm.file = file;
        this.fileList = fileList;
      // }
      
    },

    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      console.log(file);
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    reloadProof() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    // 确认上传
    submitForm(formName) {
      

      // console.log("aa")
      // var formData = new FormData($("#uploadForm")[0]);
      // var formData = new FormData($("#uploadForm")[0]);
      // console.log(formData)
      // this.$refs.uploadFile2是vue中获取dom元素的方法
      // 通过files就可以拿到所有上传的文件，如果是多个文件循环即可
      // formData.append("file", this.$refs.uploadFile2.files[0]);
      // 必须设置请求类型
      // formData.append("title", this.ruleForm.title);
      // 如果需要传id的情况下，参考下面代码
      // formData.append("author", this.ruleForm.author);
      
     
     
      if (this.fileList.length > 0) {
        var formData = new FormData();
        formData.append("file", this.fileList[0].raw);
      } else {
        var formData = new FormData($("#uploadForm")[0]);
        this.$message.error('请上传文件')
        return
      }
      if(this.fileList[0].size > 31457280){
        this.$message.error('文件过大，请更换文件重试')
        this.returns = false
        this.fileList = []
        return
      }
      this.returns = true;
      formData.append("task_type", 0);
      // console.log(this.ruleForm);
      // checks(formData)
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      // var formData = this.ruleForm;

$.ajax({
        url: process.env.VUE_APP_API_BASE_URL + "extend/upload_file/",
        // url: "https://ql-test.newacademic.net/api/extend/upload_file/",
        type: "POST",
        cache: false,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          // "Content-Type": "application/x-www-form-urlencoded",
          Token: window.localStorage.getItem("token"),
          // Token:"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NDE5NzQ4OTAsImlhdCI6MTY0MTM3MDA4MCwiZGF0YSI6eyJpZCI6MTY0LCJlbWFpbCI6IjEwMTM3MTU0NzNAcXEuY29tIiwicGhvbmUiOiIxODR4eHh4MDYwNyIsIm5hbWUiOiJcdTVmOTBcdTU2MDlcdTY1NGYiLCJmYWN1bHR5IjoiXHU1ZjAwXHU1M2QxIiwic3RhdHVzIjowfX0.629NEWBqMr2-unocUDSScxUQAhr0G3HgitzdnM4hw7E",
        },
      })
        .done(function (res) {
          if (res.error_code == 0) {
            Message({
              message: res.msg + ",请在  检测报告  中查看",
              type: "success",
            });
            this.ruleForm = {
              title: "",
              author: "",
              task_type: null,
              file: "",
            };
            this.fileList = [];
            this.reloading();
            // this.returns = false;
            // console.log("a")
          }
          if (res.error_code == 1) {
            Message.error(res.msg);
            this.returns = false;
          }
          // this.returns = false;
        })
        .fail(function (res) {
          Message.error("提交失败！！请稍后再试！");
          // this.returns = false;
        });
      this.reloadProof();
      setTimeout(() => {
        this.returns = false;
      }, 1000);
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     // console.log(this.ruleForm);

      //   } else {
      //     console.log("error submit!!");
      //     return false;
      //   }
      // });
    },
    link() {
      window.open(
        "https://support.microsoft.com/en-us/office/accept-tracked-changes-4838d24c-d5d1-4c6c-8e39-78b44bd2b4cc?ui=en-us&rs=en-us&ad=us",
        "_blank"
      );
    },
  },
  components: {
    checkDetect,
  },
};
</script>

<style lang='less' scoped>
.check {
  min-height: calc(100vh - 180px);
  // min-width: 1100px;
  // overflow-x: hidden;
  // overflow-y: scroll;
  background: white;
  padding: 30px 10%;
  display: flex;
  justify-content: center;
  .check_box {
    // width: 1000px;
    width: 1100px;
    min-width: 1100px;

    .prompt {
      // margin: 0 20%;
      .el-steps {
        margin: 20px 50px;
        color: goldenrod !important;
        /deep/ .el-step__head {
          color: #409eff;
          border-color: #409eff;
        }
        /deep/ .el-step__title {
          color: #409eff;
          font-size: 20px;
        }
        /deep/ .el-step__icon-inner {
          font-size: 30px;
          margin: 0 5px;
        }
      }
      .prompt_text {
        margin: 5px 50px;
        display: flex;
        .arrow {
          display: inline-block;
          width: 80px;
          height: 40px;
          line-height: 32px;
          text-align: center;
          border: 3px solid rgb(207, 207, 207);
          border-right: none;
          color: rgb(107, 107, 107);
          font-size: 18px;
        }
        i {
          display: inline-block;
          font-size: 55px;
          position: relative;
          top: -7px;
          left: -20px;
          font-weight: lighter;
          color: rgb(207, 207, 207);
        }
        .text {
          line-height: 40px;
          display: inline-block;
          font-size: 17px;
          width: calc(100% - 80px);
        }
      }
    }
    .upLoad {
      .el-input {
        width: 360px;
      }
      .btn {
        width: 100%;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        .el-button {
          width: 120px;
          font-size: 16px;
        }
      }
      .upDemo {
        width: calc(100% - 100px);
        // min-width: 920px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 0 45px;
        border: 1px rgb(139, 139, 139) dashed;
        border-radius: 5px;
        .upload-demo {
          // width: 920px;
          // margin: 0 40px;
          /deep/ .el-upload {
            // width: 100%;
            .el-upload-dragger {
              // width: 100%;
              // height: 300px;
              border: none;
              .upStyle {
                // display: flex;
                // justify-content: space-between;
              }
            }
          }
        }
      }
    }
  }
}
.describes {
  display: flex;
  justify-content: center;
}
.upload_tip {
  position: relative;
  margin: 50px 0 30px 0;
  background-color: #f0f7fd;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  .upload_tip_img {
    position: absolute;
    left: 50%;
    top: -50px;
    width: 100px;
    height: 100px;
    transform: translateX(-50%);
  }
  .upload_tip_title {
    font-size: 13px;
    color: red;
  }
  .upload_tip_text {
    margin-top: 16px;
    padding: 20px;
  }
  .upload_tip_p {
    margin-bottom: 4px;
    line-height: 1.5;
    color: #777;
  }
  .upload_tip_p::before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    margin-right: 10px;
    border-radius: 50%;
    vertical-align: middle;
    background-color: #666;
  }
}
.hovers {
  color: rgb(245, 135, 135);
}
.hovers:hover {
  color: rgb(94, 177, 245);
}
</style>