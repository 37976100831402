<template>
  <div class="list">
    <h2 class="title">
      <i class="el-icon-s-order"></i>我的检测报告<span
        >您提交的检测或校对论文一般将于48小时之内生成报告&emsp;&emsp;</span
      >
      <el-date-picker
        v-model="date"
        @change="datechose"
        type="daterange"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
      >
      </el-date-picker>
    </h2>
    <div class="checks">
      <div class="cen">
        <h3>查重结果</h3>
        <img
          src="../../../assets/imgs/zanwushuj.png"
          alt=""
          v-if="checkList.length == 0"
          class="not"
        />
        <div class="results" v-if="checkList.length > 0">
          <div v-for="(item, index) in checkList" :key="index" class="ones">
            <div class="one">
              <div class="image">
                <img src="../../../assets/imgs/图层 6 拷贝 3.png" alt="" />
                <i @click="deletes(item.task_id)">
                  <!-- v-if="item.status == 6 || item.status == 5" -->
                  <img src="../../../assets/imgs/删除.png" alt="" />
                </i>
              </div>

              <div class="status">
                <span class="icon" v-if="item.status !== 3"
                  ><img
                    src="../.../../../../assets/imgs/查重与校对/正在处理.png"
                    alt=""
                    style="width: 30px"
                    v-if="
                      item.status == 0 || item.status == 1 || item.status == 2
                    "
                  />
                  <img
                    src="../.../../../../assets/imgs/查重与校对/上传失败.png"
                    alt=""
                    style="width: 30px"
                    v-if="
                      item.status == 4 || item.status == 5 || item.status == 6
                    "
                  /><span class="texts" v-if="item.status == 0"
                    >排队等待...</span
                  >
                  <span class="texts" v-if="item.status == 1">正在上传...</span>
                  <span class="texts" v-if="item.status == 2">正在检测...</span>
                  <!-- <span class="texts" v-if="item.status == 3">正在检测...</span> -->
                  <span class="texts" style="color: red" v-if="item.status == 4"
                    >处理失败</span
                  >
                  <span class="texts" style="color: red" v-if="item.status == 6"
                    >已删除</span
                  >
                  <span class="texts" style="color: red" v-if="item.status == 5"
                    >任务过期</span
                  ></span
                >
                <span class="icon" v-if="item.status == 3"
                  ><img
                    src="../.../../../../assets/imgs/查重与校对/下载.png"
                    alt=""
                    style="width: 30px"
                  /><span
                    class="texts down"
                    @click="download(item.task_id, item.status)"
                    >点击下载</span
                  ></span
                >
              </div>
            </div>
            <div class="names">
              <el-popover
                placement="bottom"
                @show="contentDetail(item)"
                width="300"
                trigger="hover"
                class="popovers"
              >
                <p style="display: flex">
                  <span style="display: inline-block">文章标题：</span
                  ><span style="display: inline-block">{{ item.title }}</span>
                </p>
                <p>
                  <span>上传时间：</span
                  ><span>{{
                    item.create_time | parseTime("{y}-{m}-{d} {h}:{i}")
                  }}</span>
                </p>
                <p>
                  <span>检测状态：</span
                  ><span
                    size="mini"
                    round
                    type="success"
                    v-if="item.status == 0"
                    >排队等待</span
                  >
                  <span size="mini" round type="success" v-if="item.status == 1"
                    >正在上传</span
                  >
                  <span size="mini" round type="success" v-if="item.status == 2"
                    >正在检测</span
                  >
                  <span size="mini" round type="success" v-if="item.status == 3"
                    >检测完成</span
                  >
                  <span size="mini" round type="success" v-if="item.status == 4"
                    >处理失败</span
                  >
                  <span size="mini" round type="success" v-if="item.status == 5"
                    >任务过期</span
                  >
                  <span size="mini" round type="success" v-if="item.status == 6"
                    >已删除</span
                  >
                </p>
                <p slot="reference">{{ item.title }}</p>
              </el-popover>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="proof">
      <div class="cen">
        <h3>校对结果</h3>
        <img
          src="../../../assets/imgs/zanwushuj.png"
          alt=""
          v-if="proofreadList.length == 0"
          class="not"
        />
        <!--  -->
        <div
          class="results"
          v-if="proofreadList.length > 0 || historyFileList.length > 0"
        >
          <div
            v-for="(item, index) in proofreadList"
            :key="index"
            class="ones"
            style="position: relative; top: -55px"
          >
            <!--  -->
            <div v-if="!item.type">
              <img
                class="logo_w"
                src="../../../assets/imgs/查重与校对/图标05.png"
                alt=""
              />
              <div class="one">
                <div class="image">
                  <img src="../../../assets/imgs/图层 6 拷贝 3.png" alt="" />
                  <i @click="deletes(item.task_id)">
                    <!--  v-if="item.status !== 6 && item.status !== 5" -->
                    <img src="../../../assets/imgs/删除.png" alt="" />
                  </i>
                </div>

                <div class="status">
                  <span class="icon" v-if="item.status !== 3"
                    ><img
                      src="../.../../../../assets/imgs/查重与校对/正在处理.png"
                      alt=""
                      style="width: 30px"
                      v-if="
                        item.status == 0 || item.status == 1 || item.status == 2
                      "
                    />
                    <img
                      src="../.../../../../assets/imgs/查重与校对/上传失败.png"
                      alt=""
                      style="width: 30px"
                      v-if="item.status == 4 || item.status == 5"
                    /><span class="texts" v-if="item.status == 0"
                      >排队等待...</span
                    >
                    <span class="texts" v-if="item.status == 1"
                      >正在上传...</span
                    >
                    <span class="texts" v-if="item.status == 2"
                      >正在检测...</span
                    >
                    <!-- <span class="texts" v-if="item.status == 3">正在检测...</span> -->
                    <span
                      class="texts"
                      style="color: red"
                      v-if="item.status == 4"
                      >处理失败</span
                    >
                    <span
                      class="texts"
                      style="color: red"
                      v-if="item.status == 6"
                      >已删除</span
                    >
                    <span
                      class="texts"
                      style="color: red"
                      v-if="item.status == 5"
                      >任务过期</span
                    ></span
                  >

                  <span class="icon" v-if="item.status == 3"
                    ><img
                      src="../.../../../../assets/imgs/查重与校对/下载.png"
                      alt=""
                      style="width: 30px"
                    /><span
                      class="texts down"
                      @click="download(item.task_id, item.status)"
                      >点击下载</span
                    ></span
                  >
                </div>
              </div>
              <div class="names">
                <el-popover
                  placement="bottom"
                  @show="contentDetail(item)"
                  width="300"
                  trigger="hover"
                  class="popovers"
                >
                  <p style="display: flex">
                    <span style="display: inline-block; min-width: 70px"
                      >文章标题：</span
                    ><span style="display: inline-block">{{ item.title }}</span>
                  </p>
                  <p>
                    <span>上传时间：</span><span>{{ item.create_time }}</span>
                    <!-- item.create_time | parseTime("{y}-{m}-{d} {h}:{i}") -->
                  </p>
                  <p>
                    <span>检测状态：</span
                    ><span
                      size="mini"
                      round
                      type="success"
                      v-if="item.status == 0"
                      >排队等待</span
                    >
                    <span
                      size="mini"
                      round
                      type="success"
                      v-if="item.status == 1"
                      >正在上传</span
                    >
                    <span
                      size="mini"
                      round
                      type="success"
                      v-if="item.status == 2"
                      >正在检测</span
                    >
                    <span
                      size="mini"
                      round
                      type="success"
                      v-if="item.status == 3"
                      >检测完成</span
                    >
                    <span
                      size="mini"
                      round
                      type="success"
                      v-if="item.status == 4"
                      >处理失败</span
                    >
                    <span
                      size="mini"
                      round
                      type="success"
                      v-if="item.status == 5"
                      >任务过期</span
                    >
                    <span
                      size="mini"
                      round
                      type="success"
                      v-if="item.status == 6"
                      >已删除</span
                    >
                  </p>
                  <p slot="reference" class="titles">{{ item.title }}</p>
                </el-popover>
                <!-- <p>{{ item.title }}</p> -->
              </div>
            </div>
            <div v-if="item.type">
              <img
                class="logo_w"
                src="../../../assets/imgs/Writefull素材/icon/Writefull+Logo+Padding+250.png"
                alt=""
              />
              <div class="one">
                <div class="image">
                  <img src="../../../assets/imgs/图层 6 拷贝 3.png" alt="" />
                  <i @click="deletes_w(item.task_id)">
                    <!--  v-if="item.status !== 6 && item.status !== 5" -->
                    <img src="../../../assets/imgs/删除.png" alt="" />
                  </i>
                </div>

                <div class="status">
                  <span class="icon" v-if="item.state !== '处理完成'"
                    ><img
                      src="../.../../../../assets/imgs/查重与校对/正在处理.png"
                      alt=""
                      style="width: 30px"
                      v-if="
                        item.state == '待处理任务' ||
                        item.state == '校对中...' ||
                        item.state == '修订中...'
                      "
                    />
                    <img
                      src="../.../../../../assets/imgs/查重与校对/上传失败.png"
                      alt=""
                      style="width: 30px"
                      v-if="
                        item.state == '处理失败' ||
                        item.state == '校对失败' ||
                        item.state == '修订失败'
                      "
                    />
                    <span
                      class="texts"
                      v-if="
                        item.state == '待处理任务' ||
                        item.state == '校对中...' ||
                        item.state == '修订中...'
                      "
                      >{{ item.state }}</span
                    >

                    <span
                      class="texts"
                      style="color: red"
                      v-if="
                        item.state == '处理失败' ||
                        item.state == '校对失败' ||
                        item.state == '修订失败'
                      "
                      >{{ item.state }}</span
                    >
                  </span>
                  <span class="icon" v-if="item.state == '处理完成'"
                    ><img
                      src="../.../../../../assets/imgs/查重与校对/下载.png"
                      alt=""
                      style="width: 30px"
                    /><span
                      class="texts down"
                      @click="download_w(item.down_link)"
                      >点击下载</span
                    ></span
                  >
                </div>
              </div>
              <div class="names">
                <el-popover
                  placement="bottom"
                  width="300"
                  trigger="hover"
                  class="popovers"
                >
                  <p style="display: flex">
                    <span style="display: inline-block; min-width: 70px"
                      >文件名：</span
                    ><span style="display: inline-block">{{
                      item.filename
                    }}</span>
                  </p>
                  <p>
                    <span>上传时间：</span><span>{{ item.create_time }}</span>
                  </p>
                  <p>
                    <span>检测状态：</span
                    ><span size="mini" round type="success">{{
                      item.state
                    }}</span>
                  </p>
                  <p slot="reference" class="titles">{{ item.filename }}</p>
                </el-popover>
              </div>
            </div>
          </div>
          <!-- <div
            v-for="(item, index) in historyFileList"
            :key="index + item"
            class="ones"
            style="position: relative; top: -55px"
          >
            
          </div> -->
        </div>
      </div>
    </div>
    <!-- <div class="list_header">
      <div class="title">
        <h2 style="text-align: center; font-size: 25px">我的检测报告</h2>
      </div>
    </div> -->
    <!-- <div class="list_box">
      <div style="width: 100%">
        <ul>
          <li v-for="(item, index) in list" :key="index">
            <div class="type">
              <span>{{ item.task_type }}</span>
            </div>
            <p>
              <span>检测状态： </span>
              <el-steps
                :active="item.status + 1"
                finish-status="success"
                simple
                style="margin-top: 20px"
              >
                <el-step title="排队等待"></el-step>
                <el-step title="上传成功"></el-step>

                <el-step title="正在检测"></el-step>
                <el-step
                  v-if="item.status !== 4 && item.status !== 5"
                  title="检测完成"
                ></el-step>
                <el-step
                  v-if="item.status == 4"
                  title="处理失败"
                  status="error"
                ></el-step
                ><el-step
                  v-if="item.status == 5"
                  title="任务过期"
                  status="error"
                ></el-step
              ></el-steps>
            </p>
            <p><span>论文标题：</span>{{ item.title }}</p>
            <p><span>作者：</span>{{ item.author }}</p>
            <p><span>文件类型：</span>{{ item.file_type }}</p>
            <p>
              <span>创建时间：</span
              >{{ item.create_time | parseTime("{y}-{m}-{d} {h}:{i}") }}
            </p>
            <div class="btn">
              <el-button
                type="primary"
                icon="el-icon-download"
                round
                @click="download(item.task_id, item.status)"
                >下载报告</el-button
              >
              <el-button
              type="danger"
              icon="el-icon-delete"
              round
              disabled
              @click="deletes(item.task_id)"
              >删除报告</el-button
            >
            </div>
          </li>
        </ul>
      </div>
    </div> -->
  </div>
</template>

<script>
import { userAge, downloadable, deleteFile } from "@/Api/checkAgain";
import {
  detail_list,
  input_file,
  download,
  get_limit,
  delete_detail,
} from "@/Api/scholarcy_writeful01";
export default {
  data() {
    return {
      list: [
        // {
        //   create_time: "2022-01-11T14:12:59",
        //   task_type: "查重",
        //   title: "test_title",
        //   author: "yrq",
        //   file_type: "docx",
        //   status: 0,
        //   task_id:"8"
        // },
        // {
        //   create_time: "2022-01-11T14:13:56",
        //   task_type: "查重",
        //   title: "test_title",
        //   author: "yrq",
        //   file_type: "docx",
        //   status: 0,
        //   task_id:"8"
        // },
      ],
      date: [],
      //   actives: 5,
      currentDate: new Date(),
      checkList: [],
      proofreadList: [],
      //   animationShow: "",
      historyFileList: [],
    };
  },
  created() {
    if (this.date.length == 0) {
      // let time = new Date(this.currentDate.getTime() - 31536000000);
      // let time1 = new Date(this.currentDate.getTime());
      // console.log(time1);
      // console.log(time);
      this.date = this.getCurrentAndPreviousDate();
      console.log(this.getCurrentAndPreviousDate())
    }
    this.reportList();
  },
  methods: {
    getCurrentAndPreviousDate() {
      const currentDate = new Date(); // 获取当前日期

      // 格式化当前日期
      const formattedCurrentDate = this.formatDate(currentDate);

      // 获取前一天的日期
      const previousDate = new Date(currentDate);
      previousDate.setDate(currentDate.getDate() - 6); // 设置为前一天

      // 格式化前一天日期
      const formattedPreviousDate = this.formatDate(previousDate);

      return  [formattedPreviousDate, formattedCurrentDate]
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始，需要加1
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    // formateDate (date, fmt) {
    //   if (/(y+)/.test(fmt)) {
    //     fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    //   }
    //   let o = {
    //     'M+': date.getMonth() + 1,
    //     'd+': date.getDate(),

    //   }
    //   for (let k in o) {
    //     if (new RegExp(`(${k})`).test(fmt)) {
    //       let str = o[k] + ''
    //       fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : this.padLeftZero(str))
    //     }
    //   }
    //   return fmt
    // },
    // 左边补0函数
    padLeftZero(str) {
      return ("00" + str).substr(str.length);
    },
    //显示问题详情列表
    contentDetail(row) {
      // console.log(row);
    },
    mouseEnter() {
      this.animationShow = "paused";
    },
    datechose() {
      this.reportList();
    },
    // 查重与校对报告列表
    reportList() {
      // let time = this.formateDate(this.date[0],'yyyy-MM-dd') +' 00:00:00'
      // let time1 = this.formateDate(this.date[1],'yyyy-MM-dd') +' 23:59:59'
      let time = this.date[0] + " 00:00:00";
      let time1 = this.date[1] + " 23:59:59";
      userAge({
        year_start: time,
        year_end: time1,
      })
        .then((res) => {
          // console.log(res);
          this.list = res.data.data;
          this.checkList = [];
          this.proofreadList = [];
          this.list.forEach((item) => {
            if (item.task_type == "查重") {
              this.checkList.push(item);
            }
            if (item.task_type == "校对") {
              item.create_time = item.create_time.replace(/T/g, " ");
              // console.log(item.create_time);
              this.proofreadList.push(item);
            }
          });
          //   console.log(this.list);
          // console.log(this.proofreadList);
          let ids = {
            open_id: localStorage.getItem("code"),
            organ_name: localStorage.getItem("org_name"),
          };
          detail_list(ids)
            .then((res) => {
              // console.log(res);
              if (res.data.data) {
                this.historyFileList = res.data.data;
                this.historyFileList.forEach((item) => {
                  this.$set(item, "type", "Writeful");
                  this.proofreadList.push(item);
                });
              }

              // console.log(this.historyFileList);
              this.proofreadList = this.proofreadList.sort((a, b) => {
                let aTimeString = a.create_time;

                let bTimeString = b.create_time;
                aTimeString = aTimeString.replace(/-/g, "/");
                bTimeString = bTimeString.replace(/-/g, "/");
                let aTime = new Date(aTimeString).getTime();
                let bTime = new Date(bTimeString).getTime();

                return bTime - aTime;
              });
              // console.log(this.proofreadList);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 下载报告
    download(id, status) {
      //   console.log(id);
      if (status == 3) {
        let downId = {
          task_id: id,
        };
        downloadable(downId)
          .then((res) => {
            // console.log(res);
            window.open(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message({
          message: "当前检测未完成！请在检测完成后下载！",
          type: "warning",
        });
      }
    },
    download_w(url) {
      window.open(url, "_blank");
    },
    // 删除报告
    deletes(id) {
      this.$confirm("确认删除该报告？")
        .then((_) => {
          // console.log(id);
          let del = {
            task_id: id,
          };
          deleteFile(del)
            .then((res) => {
              console.log(res);
              if (res.data.error_code == 1) {
                this.$message({
                  message: res.data.msg,
                  type: "warning",
                });
              }
              if (res.data.error_code == 0) {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
              }
              // this.reportList();
              //   console.log("删除成功");
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((_) => {});
    },
    // 删除报告
    deletes_w(id) {
      console.log("a");
      this.$confirm("确认删除该报告？")
        .then((_) => {
          // console.log(id);
          let del = {
            task_id: id,
          };
          delete_detail(del)
            .then((res) => {
              console.log(res);
              if (res.data.code == 200) {
                this.$message({
                  message: res.data.message,
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.data.message,
                  type: "warning",
                });
              }
              this.reportList();
              //   console.log("删除成功");
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  min-height: calc(100vh - 180px);
  // overflow-x: hidden;
  // overflow-y: scroll;
  min-width: 1100px;
  //   background: white;
  //   padding: 10px 10%;
  .title {
    margin: 0px 0 20px 50px;
    color: #1f5bb6;
    font-size: 25px;
    // text-align: center;
    i {
      font-size: 25px;
      line-height: 28px;
    }
    span {
      margin-left: 20px;
      font-size: 16px;
      color: #737679;
    }
  }
  .checks {
    margin: 10px 20px;
    padding-top: 50px;
    background: white;
    padding: 20px 100px;
    min-height: calc(100vh - 790px);
    // display: flex;
    // justify-content: center;
    // align-items: center;
    h3 {
      font-size: 21px;
    }
    .not {
      width: 20%;
      margin: 0 40%;
    }
    .results {
      margin: 30px 0 0 70px;
      display: flex;
      flex-wrap: wrap;
      .ones {
        margin: 0px 30px 20px 30px;
        width: 130px;
        .one {
          //   -moz-box-shadow: 2px 2px 10px #0f0e0e;
          //   -webkit-box-shadow: 2px 2px 10px #1b1b1b;
          // box-shadow:2px 2px 10px #909090;
          box-shadow: 5px 5px 10px gray;
          border-radius: 10px;
          .image {
            width: 130px;
            height: 120px;
            background-image: url("../../../assets/imgs/图层 2 拷贝 3.png");
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            // border-start-start-radius: 10px;
            // border-start-end-radius: 10px;
            img {
              width: 80%;
            }
            i {
              overflow: hidden;
              font-size: 21px;

              color: rgb(204, 204, 204);
              position: absolute;
              top: 0px;
              right: 0px;
              z-index: 1000;
              cursor: pointer;
              img {
                width: 21px;
                color: aqua;
              }
            }
            i:hover img {
              // width: 30px;
              transform: translateY(-50px);
              filter: drop-shadow(#f13e3e 0 50px);
            }
          }

          .status {
            .icon {
              height: 45px;
              display: inline-block;
              display: flex;
              justify-content: center;
              align-items: center;
              color: rgb(0, 204, 255);
              //   height: 30px;
              .texts {
                display: inline-block;
                cursor: pointer;
                //     height: 30px;
                //   line-height: 30px;
              }
              .down :hover {
                color: rgb(36, 197, 247) !important;
              }
            }
            // background: red;
            // border-end-start-radius: 50px;
            // border-end-end-radius: 10px;
          }
        }
        .names {
          p {
            margin: 10px 5px;
            line-height: 20px;
            // width: 130px;
            word-wrap: break-word;
            word-break: break-all;
            overflow: hidden;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            cursor: pointer;
          }
        }
      }
    }
  }
  .proof {
    margin: 30px 20px 10px 20px;
    min-height: calc(100vh - 775px);
    padding-top: 50px;
    background: white;
    padding: 20px 100px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    h3 {
      font-size: 21px;
    }
    .not {
      width: 20%;
      margin: 0 40%;
    }
    .results {
      margin: 30px 0 0 70px;
      display: flex;
      flex-wrap: wrap;
      .ones {
        margin: 0px 30px 20px 30px;
        width: 130px;
        .one {
          //   -moz-box-shadow: 2px 2px 10px #0f0e0e;
          //   -webkit-box-shadow: 2px 2px 10px #1b1b1b;
          // box-shadow:2px 2px 10px #909090;
          box-shadow: 5px 5px 10px gray;
          border-radius: 10px;
          .image {
            width: 130px;
            height: 120px;
            background-image: url("../../../assets/imgs/图层 2 拷贝 3.png");
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            // border-start-start-radius: 10px;
            // border-start-end-radius: 10px;
            img {
              width: 80%;
            }
            i {
              overflow: hidden;
              font-size: 21px;

              color: rgb(204, 204, 204);
              position: absolute;
              top: 0px;
              right: 0px;
              z-index: 1000;
              cursor: pointer;
              img {
                width: 21px;
                color: aqua;
              }
            }
            i:hover img {
              // width: 30px;
              transform: translateY(-50px);
              filter: drop-shadow(#f13e3e 0 50px);
            }
            img {
              width: 80%;
            }
          }

          .status {
            .icon {
              height: 45px;
              display: inline-block;
              display: flex;
              justify-content: center;
              align-items: center;
              color: rgb(0, 204, 255);
              //   height: 30px;
              .texts {
                display: inline-block;
                cursor: pointer;
                //     height: 30px;
                //   line-height: 30px;
              }
              .down :hover {
                color: rgb(36, 197, 247) !important;
              }
            }
            // background: red;
            // border-end-start-radius: 50px;
            // border-end-end-radius: 10px;
          }
        }
        .names {
          p {
            // width: 130px;
            word-wrap: break-word;
            word-break: break-all;
            margin: 10px 5px;
            line-height: 20px;
            // font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
          }
        }
      }
    }
  }
}
.el-popover {
  p {
    margin: 10px 0;
    span {
    }
  }
}
.titles {
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.logo_w {
  width: 30px;
  position: relative;
  top: 105px;
  left: 85px;
  z-index: 100;
}
</style>